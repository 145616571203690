import { locale } from "./locales"

export const t = locale

/**
 * Replaces dynamic parts in the string, using handlebars ("{{key}}")
 * @example
 * ```
 * const t = replace("Search: {{query}}", {query: "foo"})
 * console.log(t) // Search: foo
 * ```
 *
 * When translation is dynamic, return a function instead, that replaces values in-place
 */
export function replace(translation: string, values: Record<string, any>) {
    Object.entries(values).forEach(([key, value]) => {
        translation = translation.replace(`{{${key}}}`, value?.toString() ?? "")
    })
    return translation
}
