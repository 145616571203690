import useUser from "hooks/useUser"
import { useRouter } from "next/router"
import * as React from "react"

import isAuthorized from "lib/auth"
import { signIn } from "lib/next-auth"

interface AuthProps {
    children: React.ReactNode
    auth?: boolean | string[]
}

function Auth({ children, auth }: AuthProps) {
    const { isLoading, user } = useUser()
    const _isAuthorized = Array.isArray(auth)
        ? isAuthorized(user?.roles, auth)
        : !!user

    React.useEffect(() => {
        // Do nothing while loading or already authorized
        if (isLoading || _isAuthorized) {
            return
        }
        signIn()
    }, [_isAuthorized, isLoading])

    if (isLoading || !_isAuthorized) {
        return null
    }

    return children as any
}

/** Render content conditionally if it requires auth */
export default function AuthWrapper({ children, auth }: AuthProps) {
    useForceLogin()

    if (auth) {
        return <Auth auth={auth}>{children}</Auth>
    }
    return children as any
}

/**
 * If the url contains a `forceLogin=true` param
 * and the user is not logged in yet, a login redirect
 * is triggered, after which the user is sent back to the same url.
 *
 * Used when the user is sent to this page from another domain and
 * they are supposedly already logged in on the Identity Provider.
 * This spares an extra click on the login button for the user.
 */
function useForceLogin() {
    const { query } = useRouter()
    const { authenticated } = useUser()

    React.useEffect(() => {
        if (!query.forceLogin || authenticated) {
            return
        }
        const url = new URL(window.location.href)
        url.searchParams.delete("forceLogin")
        signIn({
            options: {
                callbackUrl:
                    url.searchParams.get("redirectTo") ?? url.toString()
            }
        })
    }, [query.forceLogin, authenticated])
}
