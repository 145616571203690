export const ADMIN_ROLES = ["Handbook.Admin", "Emilia.Admin"]

export const CMS_ACCESS_ROLES = [
    "Handbook.Editor",
    "Handbook.Author",
    "Emilia.Editor",
    "Emilia.Author"
]

export const SUBSCRIBER_ROLES = [
    "Handbook.Subscriber",
    "Handbook.Neurology.Subscriber",
    "Emilia.Subscriber"
]

export const PUBLIC_ROLES = ["Everyone"]

/**
 * Takes a list of properties that are checked against user roles.
 * Users with the role `"Handbook.Admin"` are always authorized implicitly.
 * @example
 * ```js
 * // Given that user has the role `"Handbook.Subscriber"`
 * isAuthorized(user.roles, ["Handbook.Subscriber"]) // returns `true`
 * // Given that user has the role `"Handbook.Admin"`
 * isAuthorized(user.roles, []) // returns `true`
 * isAuthorized(user.roles) // returns `false`
 * isAuthorized(user.roles, ["Everyone"]) // returns `true`
 * ```
 *
 * @param userRoles
 * @param acceptedRoles
 * @returns boolean
 */
export default function isAuthorized(
    userRoles?: string[],
    acceptedRoles?: string[]
) {
    // 1. No accepted roles, public info in article is not loaded
    if (!acceptedRoles || !Array.isArray(acceptedRoles)) {
        return false
    }

    // 2. Indicates public data, anyone is authorized
    // Empty array or include one of PUBLIC_ROLES["Everyone"]
    if (
        !acceptedRoles?.length ||
        PUBLIC_ROLES.some(role => acceptedRoles.includes(role))
    ) {
        return true
    }

    // 3. User has no roles, not authorized for anything
    if (!userRoles) {
        return false
    }

    // 4. User is not subscriber, not authorized
    if (!isSubscriber(userRoles)) {
        return false
    }

    // 5. User is admin, they have access to all content
    if (isAdmin(userRoles)) {
        return true
    }

    // 6. Let's see if user has any of the accepted roles
    return userRoles.some(role => {
        return acceptedRoles.includes(role)
    })
}

/**
 * Check if the user has CMS access
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has CMS access
 */
export function hasCMSAccess(roles?: string[]) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    const acceptedRoles = [...ADMIN_ROLES, ...CMS_ACCESS_ROLES]
    return roles.some(role => acceptedRoles.includes(role))
}

/**
 * Check if the user has subscriber role
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has subscriber role
 */
export function isSubscriber(roles: string[] | undefined) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    return roles.some(role => SUBSCRIBER_ROLES.includes(role))
}

/**
 * Check if the user has admin role
 * @param roles list of roles from the user
 * @returns boolean indicating if the user has admin role
 */
export function isAdmin(roles: string[]) {
    if (!roles || !Array.isArray(roles)) {
        return false
    }

    return roles.some(role => ADMIN_ROLES.includes(role))
}
