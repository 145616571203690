/**
 * Join and prefix string, array or undefined by "/"
 * @param query Next.js query can be string, string[] or undefined
 * @returns query as string, joined and prefixed by "/"
 */
 export function toPath(query: string | string[] | undefined) {
  if (!query) {
    return "/"
  }

  if (Array.isArray(query)) {
    return ["", ...query].join("/")
  }

  if (query.startsWith("/")) {
    return query
  }

  return `/${query}`
}