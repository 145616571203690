import { grey } from "@mui/material/colors"
import { Breakpoint, createTheme } from "@mui/material/styles"

declare module "@mui/material/styles" {
    interface Theme {
        constants: {
            height: {
                footerMin: number
                header: number
                headerXSExtra: number
                breadcrumbs: number
                asideHeader: number
            }
            width: {
                contentMax: number
                leftAside: number
                rightAside: number
                galleryMin: number
            }
        }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        breakpoints: {
            values: {
                xs: Breakpoint | number
                sm: Breakpoint | number
                md: Breakpoint | number
                lg: Breakpoint | number
                xl: Breakpoint | number
            }
        }
        constants: {
            height: {
                footerMin: number
                header: number
                headerXSExtra: number
                breadcrumbs: number
                asideHeader: number
            }
            width: {
                contentMax: number
                leftAside: number
                rightAside: number
                galleryMin: number
            }
        }
    }
}

const notes = {
    // Note 1
    questionnaire: {
        details: "#E1F5F8",
        summary: {
            hover: "#B2EBF2;",
            main: "#CCF2F8"
        }
    },
    // Note 2
    "follow-up-checklist": {
        details: "#ECF5FF",
        summary: {
            hover: "#CCEAFF;",
            main: "#DDEFFF"
        }
    },
    // Note 2
    "referral-checklist": {
        details: "#ECF5FF",
        summary: {
            hover: "#CCEAFF;",
            main: "#DDEFFF"
        }
    },
    // Note 3
    "national-guidelines": {
        details: "#FCEAE8",
        summary: {
            hover: "#FCD0D0",
            main: "#FFDDDC"
        }
    },
    // Note 3
    "realtime-guidelines": {
        details: "#FCEAE8",
        summary: {
            hover: "#FCD0D0",
            main: "#FFDDDC"
        }
    },
    // Note 4 (default)
    note: {
        details: "#FEF8E7",
        summary: {
            hover: "#FFEAA6;",
            main: "#FFF2CC"
        }
    },
    // Note 5
    "priorities-guide": {
        border: "#D1D8DB",
        details: "#ffffff00",
        summary: {
            hover: "#ffffff00;",
            main: "#ffffff"
        }
    },
    // Note 6
    guidelines: {
        details: "#E2ECF3",
        summary: {
            main: "#C5D9E7",
            hover: "#A8C6DC"
        }
    },

    // Note 7
    highlight: {
        details: "#ECF5FF",
        summary: {
            hover: "#CCEAFF",
            main: "#DDEFFF"
        }
    },

    // Note 8
    urgent: {
        details: "#FCEAE8",
        summary: {
            hover: "#FCD0D0",
            main: "#FFDDDC"
        }
    },

    // Note 9
    educational: {
        details: "#E8F3F0",
        summary: {
            hover: "#AAE1D7",
            main: "#CCEBE6"
        }
    },

    collapsible: {
        border: "#D1D8DB",
        details: "#ffffff00",
        summary: {
            main: "#ffffff00",
            hover: "#D1D8DB"
        }
    }
}

const palette = {
    background: {
        dark: "#D1D8DB",
        default: "#FFFFFF",
        grey: "#E5E8F0",
        light: "#F5F5F5"
    },
    error: {
        main: "#C62828"
    },
    link: {
        dark: "#95D7EF",
        light: "#0A7FAE"
    },
    notes,
    primary: {
        dark: "#0C161D",
        light: "#2F5774",
        main: "#1F394C"
    },
    secondary: {
        contrastText: "#FFFFFF",
        dark: "#09719A",
        light: "#0C9BD4",
        main: "#0A7FAE"
    },
    tertiary: { main: "#EECCAA" },
    quarternary: { main: "#616C74" },
    highlight: {
        main: "#F4F5F5",
        border: "#DFE3EC"
    },
    success: {
        main: "#0A7FAE"
    },
    warning: {
        light: "#FFD14C",
        main: "#FFD14C"
    },
    badge: "#d2e3ed",
    epiOrange: "#f7542b"
}

const theme = createTheme({
    palette,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960, // right aside also breaks here
            lg: 1280,
            xl: 1920
        }
    },
    constants: {
        height: {
            footerMin: 128,
            header: 64,
            headerXSExtra: 56,
            breadcrumbs: 40,
            asideHeader: 46
        },
        width: {
            contentMax: 640, //NOTE: Roughly 80ch, we cannot use ch, becuase we swap fonts and they have different sizes
            leftAside: 320,
            rightAside: 320,
            galleryMin: 940
        }
    },
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.secondary?.light}`
                    },
                    "&.Mui-hover": {}
                },
                listbox: {
                    "&::-webkit-scrollbar": {
                        width: "4px"
                    },
                    "&::-webkit-scrollbar-track": {
                        marginTop: "2px",
                        backgroundColor: palette.background?.light
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: grey[500]
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.secondary?.light}`
                    }
                }
            },

            defaultProps: {
                disableRipple: true
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    "&:before": {
                        display: "none"
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                content: {
                    "&.Mui-expanded": {
                        margin: 0
                    },
                    margin: 0
                },
                root: {
                    "&.Mui-expanded": {
                        minHeight: 0
                    },
                    "@media print": {
                        background: "transparent !important"
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.primary?.dark}`
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 10
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.secondary?.light}`
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.secondary?.light}`
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    "&::-webkit-scrollbar": {
                        width: "4px"
                    },
                    "&::-webkit-scrollbar-track": {
                        marginTop: "2px",
                        backgroundColor: palette.background?.light
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: grey[500]
                    }
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                button: {
                    "&.Mui-focusVisible": {
                        outline: "none",
                        boxShadow: `0 0 0 3px ${palette.primary?.dark}`
                    }
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    marginTop: "16px",
                    marginBottom: "16px"
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        }
    },
    shape: {
        borderRadius: 2
    },
    typography: {
        fontSize: 16,
        fontFamily: "Figtree, sans-serif",
        h1: {
            fontFamily: "Figtree, sans-serif",
            fontSize: "2.3565rem",
            fontWeight: 700
        },
        h2: {
            fontFamily: "Figtree, sans-serif",
            fontSize: "1.6667rem",
            fontWeight: 700
        },
        h3: {
            fontFamily: "Figtree, sans-serif",
            fontSize: "1.2853rem",
            fontWeight: 700
        },
        h4: {
            fontSize: "1.2853rem",
            fontWeight: 400
        },
        h5: {
            fontFamily: "Figtree, sans-serif",
            fontSize: "1rem",
            fontWeight: 700
        },
        h6: {
            fontFamily: "Figtree, sans-serif",
            fontSize: "1rem",
            fontWeight: 700
        },
        body1: {
            fontSize: "1rem"
        },
        body2: {
            fontSize: "0.9rem"
        },
        subtitle1: {
            fontFamily: "Figtree, sans-serif",
            fontWeight: 700
        },
        subtitle2: {
            fontFamily: "Figtree, sans-serif",
            fontWeight: 700
        },
        caption: {
            opacity: 0.85
        }
    }
})

export default theme
