import { auth } from "config/config"
import { Session } from "next-auth"
import {
    SignInOptions,
    SignOutParams,
    SignOutResponse,
    getCsrfToken,
    signIn as naSignIn
} from "next-auth/react"
import { useEffect } from "react"
import { v4 as generateId } from "uuid"

import { getSessionId } from "@nhi/utils"

/** Normal fetch with correlation and session id headers. */
function fetchWithTrace(input: RequestInfo, init?: RequestInit | undefined) {
    return fetch(input, {
        ...init,
        headers: {
            ...init?.headers,
            "X-Session-ID": getSessionId(),
            "X-Correlation-ID": generateId()
        }
    })
}

const allowIpLoginQuery = "allowIpLogin"

/**
 * If allowIpLogin=false is set, adds flag to session storage on load
 */
export function useAllowIpLogin() {
    useEffect(() => {
        const params = new URLSearchParams(window?.location?.search)
        if (params.has(allowIpLoginQuery)) {
            const shouldDisableIP = params.get(allowIpLoginQuery)
            if (shouldDisableIP === "false") {
                window.sessionStorage.setItem(allowIpLoginQuery, "false")
            }
        }
    }, [])
}

/**
 * Looks for ?allowIPLogin=false in url.
 * Used for proxies that want to disable IP login on demand
 * Default to true
 * @returns string value of boolean value
 */
function shouldAllowIp() {
    if (typeof window === "undefined") {
        return "true"
    }
    if (window.sessionStorage.getItem(allowIpLoginQuery) === "false") {
        return "false"
    }

    return "true"
}

/**
 * Custom `next-auth` signIn method that adds
 * `X-Session-ID` an `X-Correlation-ID` to the body of the request.
 */

type SignInProps = {
    options?: SignInOptions
    authorization?: Record<string, string>
}

export function signIn(params?: SignInProps) {
    const { options, authorization } = params ?? {}

    return naSignIn(
        auth.provider,
        {
            ...options,
            "X-Session-ID": getSessionId(),
            "X-Correlation-ID": generateId()
        },
        {
            allowIpLogin: shouldAllowIp(),
            ...authorization
        }
    )
}

/**
 * Custom `next-auth` signOut method that adds
 * `X-Session-ID` an `X-Correlation-ID` headers.
 */
export async function signOut<R extends boolean = true>(
    options?: SignOutParams<R>
): Promise<R extends true ? undefined : SignOutResponse> {
    const { callbackUrl = window.location.href } = options ?? {}
    const res = await fetchWithTrace("/api/auth/signout", {
        method: "post",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        // @ts-expect-error
        body: new URLSearchParams({
            csrfToken: await getCsrfToken(),
            callbackUrl,
            json: true
        })
    })
    const data = await res.json()

    if (options?.redirect ?? true) {
        const url = data.url ?? callbackUrl
        window.location.replace(url)
        // If url contains a hash, the browser does not reload the page. We reload manually
        if (url.includes("#")) window.location.reload()
        // @ts-expect-error
        return
    }

    return data
}

/**
 * Fetches session from `/api/auth/session`,
 * parses its JSON response, and returns it.
 * If there is no session, it returns `null`
 */
export async function fetchSession(): Promise<Session | null> {
    const res = await fetchWithTrace("/api/auth/session")
    const session = await res.json()
    if (Object.keys(session).length) {
        return session
    }
    return null
}

/**
 * Automatic signOut and signIn, used for getting new claims after profile update
 * @param callbackUrl optionsl: defaults to same page
 */
export async function SignOutSignIn(callbackUrl?: string) {
    if (typeof window !== "undefined") {
        await signOut({ redirect: false })
        await signIn({ options: { callbackUrl } })
    }
}
